* {
  box-sizing: border-box;
}

body {
  font-family: 'Karla', sans-serif;
  margin: 0;
}

.header {
  background: linear-gradient(33deg, #521f8d, #521f8d, #a353ff);
  color: #fff;
  display: flex;
  padding: 0px 3%;
  align-items: center;
  height: 120px;
  box-shadow: 0px 4px 4px 0px #00000040;
  justify-content: space-between;
  flex-direction: row;
}

.header--logo {
  width: 60px;
  height: auto;
  margin-right: 1rem;
}

.header--title {
  margin-right: auto;
  font-family: 'Bangers', cursive;
  text-shadow: 3px 2px #ff7700;
  font-size: 3.5rem;
  line-height: 3rem;
  display: flex;
}

.header--desc {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: .9rem;
  line-height: 1rem;
  display: flex;
}

.meme {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}

.meme--form {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;  
  flex-direction: column;
}

.meme--inputs {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 20px;
}

input {
  border: 1px solid #b9b9b9;
  width: 48%;
  padding: 1rem;
  display: flex;
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  min-height: 50px;
}

input:focus {
  outline: none !important;
  border: 1px solid #711F8D;
}



.main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.meme {
  display: flex;
  width: 96%;
  max-width: 800px;
  margin: 2rem auto;
  
}

.meme--newimage {
  background: linear-gradient(33deg, #521f8d, #521f8d, #a353ff);
  display: flex;
  width: 67%;
  min-width: 333px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  min-height: 55px;
  border-radius: 2rem;
  box-shadow: 1px 5px 5px 1px #00000040;
  color: #fff;
  line-height: 1.5rem;
  font-family: 'Bangers', cursive;
  text-shadow: 0px 0px #ff00c8;
  border: none;
  cursor: pointer;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.meme--newimage:hover {
  text-shadow: 1px 0px #ff7700;
  background: linear-gradient(33deg, #3a1664, #521f8d, #a353ff);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.meme--newimage > img {
  width: 20px;
  height: auto;
  margin-left: 10px;
}

.meme--image {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 1.5rem;
}

.meme--image > img {
  width: 100%;
  height: auto;
}


.meme--text {
  position: absolute;
  width: 80%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin: 15px 0;
  padding: 0 5px;
  font-family: impact, sans-serif;
  font-size: 4rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow:
      2px 2px 0 #000,
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      0 2px 0 #000,
      2px 0 0 #000,
      0 -2px 0 #000,
      -2px 0 0 #000,
      2px 2px 5px #000;
}

.bottom {
  bottom: 0;
}

.top {
  top: 0;
}


@media screen and (max-width: 599px) {

  .meme--newimage {
    width: 96%;
    min-width: 96%;
    margin-bottom: 0rem !important;
    font-size: 1.25rem;
  }

  .meme--inputs {
    padding: 0px !important;
  }

  .meme--text {
    font-size: 3rem;
  }

}

@media screen and (max-width: 299px) {

  .header {
    flex-direction: column;
    height: unset;
    gap: 0;
    padding: 10px;
  }

  .header--title {
    margin-right: unset;
    margin: .5rem;
    text-align: center;
  }

  .header--desc {
    margin: .25rem;    
    text-align: center;
  }

  .meme--newimage { 
    font-size: 1rem;
    line-height: 1.1rem;
  }

  .meme--inputs {
    flex-direction: column;
  }

  .meme--uppertext {
    margin-bottom: 1rem;
  }

  input {
    width: 100%;
  }

  .meme {
    margin: 1rem .5rem;
    width: 90%;
  }

  .meme--text {
    font-size: 1em;
  }
}


